<i18n lang="yaml">
ja:
  purchased: 購入済み
en:
  purchased: purchased
</i18n>
<template>
  <HoMyVketCard
    ref="hoStoreAssetCard"
    :to="storeAsset ? `/cloudstore/asset/${storeAsset.item.id}` : undefined"
    class="ho-store-asset-card"
  >
    <template #thumbnail>
      <ClientOnly>
        <HoMyVketSkeletonScreen
          class="thumbnail-skeleton-screen"
          :is-loading-content="isLoadingItemList"
          :border-radius="isSp ? '5px' : '10px'"
          :height="getCardHeight()"
        >
          <HaBaseImage
            :src="storeAsset?.item.thumbnailUrl"
            :is-lazy="true"
            :class="'image'"
          />
        </HoMyVketSkeletonScreen>
      </ClientOnly>
    </template>

    <template v-if="!onlyThumbnail" #body>
      <ClientOnly>
        <HoMyVketSkeletonScreen
          class="name-skeleton-screen"
          :is-loading-content="isLoadingItemList"
          :border-radius="isSp ? '5px' : '10px'"
        >
          <p class="name">
            <HaLink
              :to="
                storeAsset
                  ? `/cloudstore/asset/${storeAsset.item.id}`
                  : undefined
              "
              class="link"
              >{{ storeAsset?.item.name }}</HaLink
            >
          </p>
        </HoMyVketSkeletonScreen>
      </ClientOnly>
      <ClientOnly>
        <HoMyVketSkeletonScreen
          class="info-skeleton-screen"
          :is-loading-content="isLoadingItemList"
          :border-radius="isSp ? '5px' : '10px'"
        >
          <div class="info-container">
            <template v-if="!disabledProfile && profile">
              <div class="user">
                <HaLink :to="`/profile/${profile.vketId}`" class="link">
                  <span class="icon">
                    <HaBaseImage
                      class="image"
                      :src="profile.icon.url"
                      no-image="/images/no-image-512x512.webp"
                      :draggable="false"
                    />
                  </span>
                  <span class="text">{{ profile.name }}</span>
                </HaLink>
              </div>
            </template>
            <div v-if="category !== 'like'" class="info">
              <template v-if="storeAsset?.purchased">
                <p class="-purchased">{{ i18n.t('purchased') }}</p>
              </template>
              <template v-else> ￥{{ price }} </template>
            </div>
          </div>
        </HoMyVketSkeletonScreen>
      </ClientOnly>
    </template>
    <template v-if="!isLoadingItemList && storeAsset" #thumbnail-right-bottom>
      <div class="favorite">
        <HoMyVketFavorite
          class="favorite"
          category="like"
          :is-liked="storeAsset.liked"
          :count="favoriteCount"
          :threshold="99999"
          @click="clickFavorite(storeAsset?.item.id, $event)"
        />
      </div>
    </template>
  </HoMyVketCard>
</template>

<script setup lang="ts">
import type { DeepReadonly } from 'vue'

// models
import { ItemPurchasedWithProfile } from '@/models/cloudstore'
import { ProfileDetails } from '@/models/profiles'

type Props = {
  storeAsset: DeepReadonly<ItemPurchasedWithProfile> | null
  sellerProfile?: DeepReadonly<ProfileDetails> | null
  category?: 'like'
  // NOTE: is-loading-item-list="false" を指定している親コンポーネントではスケルトンスクリーンを使っていない
  isLoadingItemList: boolean
  disabledProfile?: boolean
  onlyThumbnail?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  category: undefined,
  sellerProfile: undefined,
  disabledProfile: false,
  onlyThumbnail: false,
})

type Emits = {
  (emit: 'clickFavorite', id: number, isLiked: boolean): void
}
const emit = defineEmits<Emits>()

const isSp = breakpoints.smaller('sp')

const i18n = useI18n()
const isJa = computed(() => i18n.locale.value === 'ja')

// computed
const favoriteCount = computed(() => props.storeAsset?.item.numberOfLikes || 0)
const price = computed(() =>
  (props.storeAsset?.item.price || 0).toLocaleString()
)

const hoStoreAssetCard = ref<ComponentPublicInstance | null>(null)

const profile = computed(() => {
  return props.sellerProfile || props.storeAsset?.profile
})

const getCardHeight = (): string => {
  return hoStoreAssetCard.value
    ? `${hoStoreAssetCard.value.$el.getBoundingClientRect().width}px`
    : 'auto'
}

const clickFavorite = (id: number | undefined, isLiked: boolean) => {
  if (id === undefined) {
    throw new Error('id is undefined')
  }
  emit('clickFavorite', id, isLiked)
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-store-asset-card .thumbnail-skeleton-screen {
  height: 100%;
}

.name-skeleton-screen {
  line-height: 24px;

  @include m.sp() {
    display: none;
  }

  .name {
    > .link {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-weight: 400;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;

      &:hover {
        color: v.$gray-4;
      }
    }
  }
}

.info-skeleton-screen {
  margin-top: v.space(1.5);
}

.info-container {
  align-items: center;
  display: flex;
  gap: v.space(2);

  > .user {
    flex-grow: 1;
    @include m.sp() {
      display: none;
    }

    > .link {
      align-items: center;
      display: flex;
      gap: v.space(1);

      > .icon {
        border-radius: 50%;
        display: block;
        flex-shrink: 0;
        height: 20px;
        overflow: hidden;
        width: 20px;

        > .image {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      > .text {
        color: v.$text-note;
        font-size: 14px;
        transition: color 0.3s;
        @include m.word-ellipsis(1);
      }

      &:hover {
        > .text {
          color: v.$primary-color;
        }
      }
    }
  }

  > .info {
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 900;
    margin-left: auto;

    @include m.sp() {
      font-size: 12px;
      font-weight: 700;
      margin-left: 0;
    }

    > .-purchased {
      color: v.$gray-4;
    }
  }
}

.purchase,
.favorite {
  align-items: center;
  display: flex;

  > .icon {
    fill: v.$white;
    height: 12px;
    width: 12px;
  }

  > .count {
    color: v.$white;
    font-size: 12px;
    margin-bottom: v.space(0.5);
    margin-left: v.space(0.5);
  }
}
</style>
